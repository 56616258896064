import "./CreateUser.css";
import { Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { clearError, createUser, deleteUser, getAllUsers } from "../../actions/userAction";
import { toast } from "react-toastify";
import { MdEmail, MdPhone, MdVerifiedUser } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import Loading from "../../components/Loading/loading";
import { FaUser } from "react-icons/fa";
import { CREATE_USER_RESET } from "../../constants/userConstant";

const CreateUser = () => {
  // const { error, users } = useSelector((state) => state.allUsers);
  const { error, success, loading } = useSelector((state) => state.createUser);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [region, setRegion] = useState("");
  const [phone, setPhone] = useState("");

  const data = { name, email, role, region, phone }

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    if (user?.role !== 'admin') {
      navigate('/dashboard')
    }
  }, [user])

  useEffect(() => {
    // toast.success("perfect");
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
    if (success) {
      toast.success("Invite send Successfully")
      navigate('/user')
      dispatch({ type: CREATE_USER_RESET })
    }

  }, [error, success]);

  const createUserHandler = (e) => {
    e.preventDefault()
    dispatch(createUser(data));


  };

  return (
    <div className="dashboardMain">
      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar />
      </div>
      {
        loading ? <Loading /> : (
          <div className="dashboardContainer">
            {/* <DashboardHeader heading="Create User " /> */}

            {/* <div className="ButtonLayer">
            <div className="blBox1">
              <div className="totalUser">
                <p>
                  Total User <br /> 45
                </p>
              </div>
              <div className="blocklUser">
                <p>
                  Block User <br /> 45
                </p>
              </div>
            </div>
          </div> */}
            <div className="createUserContainer center">
              <form action="" onSubmit={(e) => createUserHandler(e)}>
                <h3>Invite New User</h3>
                <div>
                  <FaUser />
                  <input
                    type="name"
                    required
                    className="inpBtn"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <MdEmail />

                  <input
                    type="email"
                    required
                    className="inpBtn"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <MdPhone />

                  <input
                    type="number"
                    required
                    className="inpBtn"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div>
                  <MdVerifiedUser />
                  <select required value={role} onChange={(e) => setRole(e.target.value)} name="" id="" className="inpSelBtn">
                    <option value="">Select Role</option>
                    <option value="admin">admin</option>
                    <option value="counsellor">counsellor</option>
                    <option value="supervisor">supervisor</option>
                    <option value="agent">agent</option>
                    <option value="processor">processor</option>
                  </select>
                </div>
                <div>
                  <MdVerifiedUser />
                  <select required value={region} onChange={(e) => setRegion(e.target.value)} name="" id="" className="inpSelBtn">
                    <option value="">Select Region</option>
                    <option value="usa">USA</option>
                    <option value="uk">UK</option>
                    <option value="canada">Canada</option>
                    <option value="australia">Australia</option>
                  </select>
                </div>
                <div>
                  <input type="submit" className="submintBtn" value={"Create "} placeholder="name" />
                </div>
              </form>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CreateUser;
