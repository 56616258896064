import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./FileUpload.css";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader.js";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UPLOAD_FILE_RESET } from '../../constants/fileConstant'
import axios from "axios";
import BasicDemo from "../../components/Card";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import SingleColumnDemo from "../../components/DataTable";
import { fileUpload, getStudentsData } from "../../actions/fileAction";
import Loading from "../../components/Loading/loading";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { AiTwotoneFileExcel } from "react-icons/ai";

const FileManegment = () => {
  const { error, isUploaded, loading } = useSelector((state) => state.fileUpload);
  const Navigate = useNavigate()

  const { user } = useSelector((state) => state.users);
  useEffect(() => {
    if (user?.role !== 'admin' && user?.role !== 'supervisor' &&  user?.role !== 'agent') {
      Navigate('/dashboard')
    }
  }, [user])

  const dispatch = useDispatch();
  useEffect(() => {
    if (isUploaded) {
      toast.success("File Upload Successfully.")
      Navigate(-1)
      dispatch({ type: UPLOAD_FILE_RESET })
    }
  }, [isUploaded, error]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setfileName] = useState('')

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setfileName(file?.name)

    if (file) {
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (file.size <= 25 * 1024 * 1024) {

          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => {
            setSelectedFile(e.target.result);
            // console.log(selectedFile)
          }
        } else {
          event.target.value = null;
          setSelectedFile(null);
          alert("File size exceeds the limit of 25 MB.");
        }
      } else {
        event.target.value = null;
        setSelectedFile(null);
        alert("Please select an Excel file.");
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {

      const formData = new FormData();
      formData.append("file", selectedFile);

      dispatch(fileUpload(formData));
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const handleStudentsDetails = async () => {
    dispatch(getStudentsData());
  };

  return (
    <div className="dashboardMain">
      <div
        className={
          window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
        }
      >
        <Sidebar />
      </div>

      <div className="dashboardContainer">
        {/* <DashboardHeader heading={"File Manegment"} /> */}

        <div className="D-ButtonLayer"></div>
        {
          loading ? <Loading /> : (
            <Fragment>

              <div className="template">
                <a href={require('../../images/gleamExcelTemplate.xlsx')} download='gleamExcelTemplate' className="docBtn"> Download Template  <AiTwotoneFileExcel /></a>

              </div>
              <div className="createUserContainer">
                <form onSubmit={(e) => handleUpload(e)}>
                  <h3>Upload file</h3>

                  <h5 className="label">Please upload xlsx  file only. Accept 25mb file only.</h5>
                  <div className="inpBorder">
                    {/* <MdVerifiedUser /> */}
                    <input
                      className="intFileBtn"
                      required
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={handleFileChange}
                      name="file"
                      // placeholder="k"
                    />
                  </div>
                  {
                    fileName ?
                      <p className="fileName">File Name : {fileName}</p> :
                      <p className="fileName">No File Select</p>


                  }
                  <div>

                    {
                      selectedFile && selectedFile.name}
                  </div>
                  <div>
                    <input
                      type="submit"
                      className="submintUploadBtn"
                      value={"File Upload "}
                    />
                  </div>
                </form>
              </div>

              {/* <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          name="file"
        />
        <button onClick={handleUpload}>Upload</button>
        {selectedFile && <p>Selected File: {selectedFile.name}</p>} */}
            </Fragment>
          )
        }

      </div>

      {/* </div> */}
    </div>
  );
};

export default FileManegment;
