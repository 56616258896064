import "./UserManage.css";
import "../FileMangement/FileManagement.css";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FiEdit } from "react-icons/fi";
import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import NoDataFound from "../../components/NoDataFound.js";
import UserHeader from "../Dashboard/UserHeader.js";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser, getAllUsers } from "../../actions/userAction";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/loading";
import { FaCopy, FaCopyright, FaFile, FaFileAlt, FaUserAlt, FaUsers, FaUsersSlash } from "react-icons/fa";
import { BiBlock } from "react-icons/bi";
import Modal from 'react-modal';
import MetaData from "../../components/MetaData";
import Pagination from "react-js-pagination";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
// Modal.setAppElement('');

const UserManage = ({ setUnderLine, underLine }) => {
  setUnderLine('users-management')
  const { error, users, totalUsers, resultPerPage, loading } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.users);
  const [CurrentPage, setCurrentPage] = useState(1)
  const Navigate = useNavigate();


  // const totalUsers = users.length
  let totalBolackUsers = 0
  users && users.forEach(element => {
    if (element.isActive === false) {
      totalBolackUsers += 1;
    }


  });
  //   (state) => state.profile
  // );

  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(getAllUsers(CurrentPage));
  }, [error, CurrentPage, dispatch]);

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
  };

  useEffect(() => {
    if (user?.role !== 'admin') {
      Navigate('/dashboard')
    }
  }, [user, Navigate])
  const columns = [
    { field: "id", headerName: "S No.", minWidth: 80, flex: 0.2  },

    {
      field: "email",
      headerName: "Email",
      minWidth: 180,
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 0.6,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 100,
      flex: 0.3,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "role") === "admin"
      //     ? "greenColor"
      //     : "yellowColor";
      // },
    },
    {
      field: "id_",
      headerName: "Id",
      minWidth: 200,
      flex: 0.5,
      renderCell: (params) => {
        // console.log(params.row);


        const copyToClipboard = () => {
          navigator.clipboard.writeText(params.row?.id_).then(
            () => {
              toast.success('Id copied to successful')
            },
            (err) => {
              console.error('Failed to copy text: ', err);
            }
          );
        };
        return (
          <button className={'grayColor'} onClick={copyToClipboard}>{params.row?.id_.slice(0, 10)}... <FaCopy /></button>
        )
      }

    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.3,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "role") === "admin"
      //     ? "greenColor"
      //     : "yellowColor";
      // },
      renderCell: (params) => {
        // console.log(params.row);
        return (
          <p className={params.row?.status === 'Active' ? 'greenColor' : 'redColor'}>{params.row?.status}</p>
        )
      }

    },
    // {
    //   field: "verification",
    //   headerName: "Verification",
    //   minWidth: 100,
    //   flex: 0.3,

    //   // cellClassName: (params) => {
    //   //   return (params.getValue(params.id, "role"))
    //   // },
    //   renderCell: (params) => {
    //     return (
    //       <p className={params.row?.verification === 'not verify' ? 'unverified' : 'verified'}>{params.row?.verification}</p>
    //     )
    //   }
    // },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 180,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        // console.log(params.row)
        return (
          <Fragment className="actionIconBox">
            <Link to={`/applications/${params.row.id_}`} className="student-icons">
              <FaFileAlt style={{ color: 'rgb(34, 61, 79)' }} />
            </Link>

            <Link to={`/user/update/${params.row.id_}`} className="student-icons">
              <FiEdit style={{ color: 'rgb(34, 61, 79)' }} />
            </Link>
            <Button onClick={() => openModal(params.row.id_)} className="student-icons">
              {/* onClick={() => deleteUserHandler(params.id)} */}
              <BiBlock className="" style={{ color: 'tomato' }} />
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const rows = [];

  users &&
    users.forEach((item, i) => {
      rows.push({
        id: i + 1,
        id_: item._id,
        name: item.name,
        status: item.isActive ? 'Active' : 'InActive',
        // verification: item.verification,
        role: item.role,
        email: item.email,
      });
    });
  // const clearNavbar = () => {

  //   if (window.innerWidth <= 600) {

  //     document.getElementsByClassName("menuContainer")[0].classList.add("hide");
  //     document
  //       .getElementsByClassName("menuContainer")[0]
  //       .classList.remove("show");
  //   }
  // };

  // modal
  const [userId, setuserId] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(userId) {
    setuserId(userId)
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeAndDeleteUser() {
    {/* onClick={() => deleteUserHandler(params.id)} */ }
    deleteUserHandler(userId)
    setIsOpen(false);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="dashboardMain">
      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />
      </div>
      {
        loading ? <Loading /> : (
          <div className="dashboardContainer">
            <MetaData title={"User Management"} />
            <UserHeader heading="User Management" />

            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modalBox">

                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Are you sure Block the user?</h2>
                <div>
                  <button onClick={closeModal}>close</button>
                  <button onClick={closeAndDeleteUser}>Yes</button>

                </div>
              </div>
            </Modal>

            <div className="D-ButtonLayer">
              <div className="BlRow">
                <div className="BlItem BlItem3">
                  <div>
                    <p>Total Users</p>
                    <p>{totalUsers}</p>
                  </div>
                  <div className="">
                    <FaUsers />
                  </div>
                </div>
                <div className="BlItem  BlItem4">
                  <div>
                    <p>Blocked Users</p>
                    <p>{totalBolackUsers}</p>
                  </div>
                  <div className="">
                    <FaUsersSlash />
                  </div>
                </div>

              </div>
            </div>

            <div className="usersContainer">

              {
                rows.length !== 0 ? (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    disableSelectionOnClick
                    className="usersTable"
                    autoHeight
                    // autoWidth
                    sx={{
                      padding: 2,
                      fontSize: 14,
                      backgroundColor: 'white',
                      boxShadow: 0,
                      fontFamily: 'Roboto',
                      color: 'black',
                      borderRadius: 0,
                      headerColor: 'primary.light',

                      '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                      },

                    }}
                  />


                ) : <NoDataFound />
              }


            </div>
            {
              totalUsers > resultPerPage &&
              <div className="paginationContainer">
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={totalUsers}
                  onChange={e => setCurrentPage(e)}

                  className="paginationBox"

                  prevPageText="<"
                  firstPageText="1st"
                  lastPageText="Last"
                  nextPageText=">"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            }
          </div>
        )
      }

    </div>
  );
};

export default UserManage;
