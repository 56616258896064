import React, { useEffect, useState } from "react";
import { FaDownload, FaEdit, FaEye, FaEyeSlash, FaGoogleDrive, FaWhatsapp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getStudent } from "../../actions/fileAction";
import { clearError as userClearError } from "../../actions/userAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import Sidebar from "../../components/Sidebar";
import { getStatus, getTeamName } from "../../components/student";
import "./StudentDetails.css";



const StudentDetails = () => {
  const dispatch = useDispatch();
  const { error, student, upload_by_user, assigned_user, loading } = useSelector((state) => state.oneStudent);
  const { user } = useSelector((state) => state.users);

  const { id } = useParams();
  const navigate = useNavigate();

  let date = new Date(student?.createdAt)
  const date_ = date.toString().split('G')[0]
  // console.log(student?.createdAt , date_)
  let update_date = new Date(student?.updatedAt)
  const update_date_ = update_date.toString().split('G')[0]

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(userClearError());
      dispatch(clearError());
      // setSelectedMultiFile([])
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getStudent(id));
    }
  }, [id]);



  const arrays = ["png", "jpg", "jpeg"]


  // ===================================================================
  const [viewTable, setViewTable] = useState([
    {
      id: 1,
      isView: false
    },
    {
      id: 2,
      isView: false
    },
    {
      id: 3,
      isView: false
    },
    {
      id: 4,
      isView: false
    },
  ]);


  const handleView = (id, isView) => {

    const updatedTable = viewTable.map(ele =>
      ele.id === id ? { ...ele, isView } : { ...ele, isView: false }
    );

    setViewTable(updatedTable);  // This will trigger a re-render
  };

  const viewItem = (id) => {
    const item = viewTable.find(item => item.id === id);
    return item ? item.isView : false;
  };

  console.log(student)

  const [isDownload, setIsDownload] = useState(false)
  const downloadFile = async (url, fileName) => {
    setIsDownload(true)
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      setIsDownload(false)

      // Ensure the file type is supported (pdf, img, doc, docs)
      // const supportedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      // if (!supportedTypes.includes(blob.type)) {
      //   throw new Error('Unsupported file type.');
      // }

      // Create a URL for the blob and trigger download
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release memory after download
      window.URL.revokeObjectURL(blobUrl);

    } catch (error) {
      console.error('Error downloading the file:', error);
      setIsDownload(false)

    }
  };


  const handleWhatsAppRedirect = () => {
    const phoneNumber = student?.Phone;
    const formattedNumber = phoneNumber.replace(/\D/g, ''); // Removes non-numeric characters
    const whatsappLink = `https://wa.me/+91${formattedNumber}`;

    // Redirect to WhatsApp
    window.open(whatsappLink, '_blank');
  };
  // console.log(student?.documentsFiles)


  return (
    <div className="dashboardMain">
      <MetaData title={`Application - ${student?.Name}`} />

      <div
        className={
          window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
        }
      >
        <Sidebar />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboardContainer">
          {/* <DashboardHeader heading={"Students Application"} /> */}

          <div className="StudentDetailsBox">
            <div className="studentUnderBox">
              <div className="su-topHeader">
                <h3>
                  Application ID : {student?.Student_Id} <br />
                  Last Update :{" "}
                  {student?.updatedAt === null ? "No Update" : update_date_}
                </h3>

                <Link to={`/student/details/update/${id}`} className="editBtn">Edit <FaEdit /> </Link>
              </div>

              {/* <button onClick={e => downloadFile('https://drive.google.com/file/d/152DMyPGggzDtk8HroFPfidP8iWkkVy9G/view', 'g-drive')}>Gdrive download</button> */}

              <div className="StatusBox">

                <div>

                  <h4>STATUS</h4>
                  <p className={
                    (student?.status === '0' && 'newApp') ||
                    (student?.status === '1' && 'underReviewApp') ||
                    (student?.status === '2' && 'underReviewApp') ||
                    (student?.status === '3' && 'approvedApp') ||
                    (student?.status === '4' && 'rejectApp')
                  }  >{getStatus(student?.status)}</p>
                </div>



                <button className="whatsappBtn" onClick={handleWhatsAppRedirect}>
                  <FaWhatsapp /> Open in WhatsApp
                </button>

              </div>


              <div className="st-d-item">
                <div>
                  <p><b>Name :</b></p>


                  <p>{student?.Name}</p>

                </div>
                <div>
                  <p>
                    {" "}
                    <b>Course :</b>
                  </p>
                  <div className="viewBox">
                    <p>{viewItem(1) ? student?.Course : '******'}</p>
                    <button className="viewBtn" onClick={e => handleView(1, !viewItem(1))}>{viewItem(1) ? <FaEye /> : <FaEyeSlash />}</button>
                  </div>
                </div>
              </div>

              <div className="st-d-item">
                <div>
                  <p>
                    <b>Phone :</b>
                  </p>

                  <div className="viewBox">
                    <p>{viewItem(2) ? student?.Phone : '******'}</p>
                    <button className="viewBtn" onClick={e => handleView(2, !viewItem(2))}>{viewItem(2) ? <FaEye /> : <FaEyeSlash />}</button>
                  </div>
                </div>
                <div>
                  <p>
                    {" "}
                    <b>Email :</b>
                  </p>
                  <div className="viewBox">
                    <p>{viewItem(3) ? student?.Email : '******'}</p>
                    <button className="viewBtn" onClick={e => handleView(3, !viewItem(3))}>{viewItem(3) ? <FaEye /> : <FaEyeSlash />}</button>
                  </div>
                </div>
              </div>






              <div className="st-d-item">
                <div>
                  <p>
                    <b>Team Name:</b>
                  </p>
                  <p>{getTeamName(student?.Team_Name)}</p>
                </div>
                <div>
                  <p>
                    {" "}
                    <b>Intake:</b>
                  </p>
                  <p>{student?.Intake}</p>
                </div>
              </div>


              <div className="st-d-item">
                <div>
                  <p>
                    <b>Date Of Birth:</b>
                  </p>
                  <div className="viewBox">
                    <p>{viewItem(4) ? student?.Date_Of_Birth : '******'}</p>
                    <button className="viewBtn" onClick={e => handleView(4, !viewItem(4))}>{viewItem(4) ? <FaEye /> : <FaEyeSlash />}</button>
                  </div>
                </div>
                <div>
                  <p>
                    {" "}
                    <b>Region:</b>
                  </p>
                  <p>{student?.Region}</p>
                </div>
              </div>




              {/* last  */}

              <div className="st-d-item">
                <div>
                  <p>
                    <b>Country :</b>
                  </p>
                  <p>{student?.Country}</p>
                </div>
                <div>
                  <p>
                    {" "}
                    <b>Create At:</b>
                  </p>
                  <p>{date_}</p>

                </div>
              </div>


              <div className="st-d-item">
                {/* <div>
                  <p>
                    <b>
                      Lead from State:</b>
                  </p>
                  <p>{student?.Lead_from_State}</p>
                </div> */}

                <div>
                  <p>
                    <b>University Name:</b>
                  </p>
                  <p>{student?.University_Name}</p>
                </div>

                {/* <div>
                  <p>
                    {" "}
                    <b>Status</b>
                  </p>
                  <p>{getStatus(student?.status)}</p>
                </div> */}
              </div>

              {/* <div className="st-d-item">
                <div>
                  <p>
                    <b>University Name:</b>
                  </p>
                  <p>{student?.University_Name}</p>
                </div>
              </div> */}

              <br />
              <div className="commentBox">
                <p><b>If Past Visa Rejected:</b></p>
                <p>{student?.visaRejected}</p>
              </div>

              <br />

              <div className="commentBox">
                <p><b>Comments:</b></p>
                <p>{student?.comments}</p>
              </div>


              {
                user?.role !== 'agent' &&
                <div className="driveBox">
                  {
                    student?.File_Drive_Link &&
                    <a href={student?.File_Drive_Link} target="_black" className="driveLinkBtn"> <FaGoogleDrive /> Google Drive </a>
                  }
                </div>
              }

              {/* images / documents  */}

              <h3 className="doc_heading">Documents</h3>
              <div className="st-document-item">


                {student?.documentsFiles &&
                  <>
                    {
                      student?.documentsFiles.map((item) => (
                        <div className="doc-item">
                          <div>
                            <p>
                              <b></b> {item.doc_name}
                            </p>
                            {
                              isDownload ? '...' :
                                <button className="doc-download" onClick={e => downloadFile((item.url).split(':')[0] === 'https' ? item.url : 'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/' + item?.key, item.doc_name)}>
                                  <FaDownload />
                                </button>
                            }
                            {/* <a className="doc-download" target="_black" href={'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/'+item?.key}>
                              <FaEye />
                            </a> */}
                          </div>

                          <div>
                            {/* {
                              arrays.includes(item.url.split(".")[2])
                            } */}
                            {
                              // arrays.includes(item.url.split(".")[2]) ? (<img src={item.url} alt="file" />) : (<img src={'https://cdn-icons-png.flaticon.com/512/2246/2246713.png'} alt="file" />)
                              item.doc_type.split("/")[0] === 'image' ? (<img src={item?.url} alt="file" />) : (<img src={require('../../images/file.png')} alt="file" />)
                            }

                          </div>
                        </div>
                      )
                      )
                    }
                  </>
                }

              </div>

            </div>

            {
              user.role !== 'agent' &&
              <>

                <div className="studentUnderBox">
                  {/* {user?.name} */}
                  <div className="lead-upload-box">
                    <div className="assign-topHeader">

                      <h3>Application / Lead Assign To </h3>


                      <Link to={`/student/asign/${id}`} className="editBtn assignBtn">Assign <FaEdit /> </Link>

                    </div>
                    <br />
                    {
                      assigned_user ?
                        <div>
                          <p>Name : {assigned_user?.name}</p>
                          <p>Email : {assigned_user?.email}</p>
                          <p>Role : {assigned_user?.role}</p>
                        </div>
                        : <div>
                          <h2 className="notFoundText">{assigned_user === false ? <p style={{color:"red", fontSize:'0.9vmax'}}>Application not assign or assigned with wrong useId. Please assign again.</p> : "Application not assign "}</h2>
                        </div>
                    }


                  </div>

                </div>

              </>
            }
            {
              (user.role === 'admin' || user.role === 'supervisor') &&
              <div className="studentUnderBox">
                {/* {user?.name} */}
                <div className="lead-upload-box">
                  <h3>Application / Lead Upload By </h3>
                  <br />
                  {
                    upload_by_user ?
                      <div>
                        <p>Name : {upload_by_user?.name}</p>
                        <p>Email : {upload_by_user?.email}</p>
                        <p>Role : {upload_by_user?.role}</p>
                      </div>
                      :
                      <div>
                        <h2 className="notFoundText">Detail Missing </h2>
                      </div>
                  }
                </div>
              </div>
            }

          </div>


        </div>
      )}
    </div>
  );
};

export default StudentDetails;
