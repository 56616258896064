import "./sidebar.css";
// import logo from '../../image
import { useEffect } from "react";
import { FaClock, FaHouseUser, FaImage, FaMapMarkedAlt, FaRegFileAlt, FaTv, FaUniversity, FaUserCheck, FaUserClock, FaUsers, FaVideo } from "react-icons/fa";
import { ImUserPlus } from "react-icons/im";
import { RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../actions/userAction";
import Logo from '../images/gleam_logo.png'

// import ExpandMoreIcon from '@mui/material'
// import AddIcon from '@mui/mate
// import ImportExportIcon from '@mui/material'

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);

  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


const Sidebar = ({ underLine }) => {
  const { error, user, isAuthenticated } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const token = Cookies.get('token');
  // console.log('Token:', token);

  const token = getCookie('token');

  // console.log(token);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if (!isAuthenticated) {
      navigate("/");
    }
    if (!token) {
      dispatch(logout())
    }
  }, [error]);

  // const logOutHandler = () => {
  //   dispatch(logout());
  //   navigate("/");
  // };

  const clearNavbar = () => {

    if (window.innerWidth <= 600) {

      document.getElementsByClassName("menuContainer")[0].classList.add("hide");
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("show");
    }
  };
  return (
    <div className="sideBarContainer">
      <div className="sidebar">
        <p className="BrandLogo">
          {/* <img
            src={Logo}
            alt="GLEAM"
          /> */}
          <h1>Submit App</h1>
        </p>

        <Link to="/dashboard" className={underLine === 'dashboard' ? "active" : ""} onClick={(e) => {
          clearNavbar();
        }} >
          <p>
            <RxDashboard />
            Dashboard
          </p>
        </Link>
        {
          user?.role === 'admin' && (
            <h4>User</h4>
          )}
        {
          user?.role === 'admin' && (

            <Link
              to="/user"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'users-management' ? "active" : ""}
            >
              <p>
                <FaHouseUser />
                Users Management
              </p>
            </Link>
          )
        }

        {
          user?.role === 'admin' || user?.role === 'supervisor' || user?.role === 'agent' ? (
            <>
              <h4>FILE</h4>
              <Link
                to="/file/management"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'file-management' ? "active" : ""}

              >
                <p>
                  <FaRegFileAlt />
                  File Management
                </p>
              </Link>
            </>

          ) : ""}

        <h4>{(user?.role === 'admin' || user?.role === 'supervisor' || user?.role === 'counsellor') ? "Leads / Applications" : "Applications"}</h4>
        {
          user?.role === 'admin' || user?.role === 'agent' || user?.role === 'supervisor' || user?.role === 'processor' || user?.role === 'counsellor' ? (

            <Link
              to="/asigned/applications"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'ass-management' ? "active" : ""}
            >
              <p>
                <ImUserPlus />
                {user?.role === 'counsellor' ? "Assigned Leads / Applications" : "Assigned Applications"}

              </p>
            </Link>
          ) : ''
        }
        {
          user?.role === 'admin' || user?.role === 'supervisor' || user?.role === 'counsellor' ? (
            <>

              <Link
                to="/student/leads"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'leads-management' ? "active" : ""}

              >
                <p>
                  <FaUsers />
                  Leads Management
                </p>
              </Link>
            </>
          ) : ""}
        {
          user?.role === 'admin' || user?.role === 'agent' || user?.role === 'supervisor' || user?.role === 'processor' || user?.role === 'counsellor' ? (
            <>

              <Link
                to="/student/application"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'app-management' ? "active" : ""}

              >
                <p>
                  <FaUserClock />
                  Applications Management
                </p>
              </Link>
            </>
          ) : ""}



        {
          user?.role === 'admin' || user?.role === 'counsellor' || user?.role === 'processor' || user?.role === 'supervisor' ? (
            <>


              <Link
                to="/assigned/applications/history"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'app-history' ? "active" : ""}

              >
                <p>
                  <FaUsers />
                  My Processed Applications
                </p>
              </Link>
            </>
          ) : ""}

        {
          user?.role === 'agent' ? (
            <Link
              to="/processed/leads"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'processed-management' ? "active" : ""}

            >
              <p>
                <FaUserCheck />
                Processed Applications
              </p>
            </Link>
          ) : ""}

        {/* -----------------------------university------------------------- */}
        {
          (user?.role === 'admin' || user?.role === 'supervisor') && (

            <Link
              to="/university-management"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'university-management' ? "active" : ""}
            >
              <p>
                <FaUniversity />
                University Management
              </p>
            </Link>
          )
        }

        {
          (user?.role !== 'admin' && user?.role !== 'supervisor') &&
          (
            <Link
              to="/universities"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'universities' ? "active" : ""}
            >
              <p>
                <FaUniversity />
                Universities
              </p>
            </Link>
          )
        }

        {/* ----------------------------------------- Monitoring --------------------------------------------- */}
        
       
        {
          user?.role === 'admin' &&
          <>
            <h4>MONITORING</h4>

            <Link
              to="/monitoring"
              onClick={(e) => {
                clearNavbar();
              }}
              className={underLine === 'monitoring' ? "active" : ""}
            >
              <p>
                <FaTv />
               Users Monitoring
              </p>
            </Link>

            <>
              <Link
                to="/active-app"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'active-apps' ? "active" : ""}>
                <p>
                  <FaMapMarkedAlt />
                  App History
                </p>
              </Link>

              <Link
                to="/clock-in-out"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'logs' ? "active" : ""}>
                <p>
                  <FaClock />
                  Clock In-Out
                </p>
              </Link>

              <Link
                to="/screenshots"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'screenshots' ? "active" : ""}>
                <p>
                  <FaImage />
                  Screenshots
                </p>
              </Link>

              <Link
                to="/recordings"
                onClick={(e) => {
                  clearNavbar();
                }}
                className={underLine === 'recordings' ? "active" : ""}>
                <p>
                  <FaVideo />
                  Recordings
                </p>
              </Link>
            </>
          </>
        }
         

        {/* ----------------------------------------- Monitoring --------------------------------------------- */}


        <p className="designAvenueAD" >
          <a href="https://www.gleamrecruits.com/" target="_black" className="designAvenueAD daLink">Powered by Gleam Education</a>
          Developed and managed by
          <a href="https://www.designavenue.co.in" target="_black" className="daLink">Design Avenue</a>
        </p>

      </div>




    </div>
  );
};

export default Sidebar;
