import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FaPencilAlt, FaSearch, FaUserAlt, FaUserPlus } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    clearError,
    getAssignedApplicationsHistory,
} from "../../actions/fileAction.js";
import Loading from "../../components/Loading/loading.js";
import MetaData from "../../components/MetaData.js";
import NoDataFound from "../../components/NoDataFound.js";
import Sidebar from "../../components/Sidebar.js";
import { getStatus } from "../../components/student.js";
import AsignedHeader from "./AsignedHeader.js";
import "./FileManagement.css";
// import { Link } from "@mui/material";

// Modal.setAppElement('#yourAppElement');

const AsignedApplication = ({ underLine, setUnderLine }) => {
  setUnderLine("app-history");

  const [searchWord, setSearchWord] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch();
  const { error, students, resultPerPage, totalfilterStudents, loading } = useSelector(
    (state) => state.asignApplications
  );
  const {
    error: userError,
    user,
    loading: userLoading,
  } = useSelector((state) => state.users);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    searchHandler();
    // dispatch(getAssignedApplicationsHistory(user._id))
  }, [CurrentPage]);

  const Navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'admin' && user?.role !== 'agent' && user?.role !== 'counsellor' && user?.role !== 'processor' && user?.role !== 'supervisor') {
      Navigate('/dashboard')
    }
  }, [user])

  const columns = [
    { field: "id", headerName: "S No.", minWidth: 200, flex: 0.5 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 0.5,
    },
    {

      // -----
      field: "status",
      headerName: "Status",
      minWidth: 220,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        // console.log("dsf",params);
        const status = getStatus(params.row.status)
        const statusNo = params.row.status

        let className = 'unknown'

        // console.log(typeof statusNo)


        return (
          <Fragment className="actionIconBox">
            <p className={
              (statusNo === '0' && 'newApp') ||
              (statusNo === '1' && 'underReviewApp') ||
              (statusNo === '2' && 'underReviewApp') ||
              (statusNo === '3' && 'approvedApp') ||
              (statusNo === '4' && 'rejectApp')
            } >{status}</p>
          </Fragment>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.id, "role"))
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 240,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        // console.log("dsf",params.row.id_);
        return (
          <Fragment className="actionIconBox">
            <Link
              to={`/student/details/${params.row.id_}`}
              className="student-icons"
            >
              <AiFillEye />
            </Link>
            <Link
              to={`/student/details/update/${params.row.id_}`}
              className="student-icons"
            >
              <FaPencilAlt />
            </Link>
            {
              user?.role !== 'agent' &&
              <Link className="student-icons" to={`/student/asign/${params.row.id_}`} >
                <FaUserPlus />
              </Link>
            }
          </Fragment>
        );
      },
    },
  ];

  const rows = [];

  students &&
    students.forEach((item, i) => {
      let date = new Date(item?.createdAt)
      console.log(item)
      rows.push({
        id_: item._id,
        id: item?.Student_Id || 'GR-None',
        name: item.Name,
        status: item.status,

        country: item.Country,
        createAt: date.toString().split('G')[0]
      });
    });

  // modal code



  const searchHandler = (e) => {
    dispatch(getAssignedApplicationsHistory(user?._id, searchWord, CurrentPage));
  };

  const searchByHandler = (e) => {
    setCurrentPage(1)
    dispatch(getAssignedApplicationsHistory(user?._id, searchWord, CurrentPage));
  };

  return (
    <div className="dashboardMain">
      <MetaData title={"Application Management"} />

      <div
        className={
          window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
        }
      >
        <Sidebar underLine={underLine} />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboardContainer">
          <AsignedHeader heading={"Application"} />

          <div className="D-ButtonLayer">
            <div className="BlRow1">
              <div className="BlItem BlItem4">
                <div>
                  <p>Total Applications</p>
                  <p>{totalfilterStudents && totalfilterStudents}</p>
                </div>
                <div className="">
                  <FaUserAlt />
                </div>
              </div>

              <div className="student-search-box">
                <form action="" onSubmit={searchByHandler}>
                  <input
                    type="text"
                    placeholder="Search something here..."
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                  />
                  <button type="submit" className="submitBtn" onClick={searchByHandler}  value={'find'} ><FaSearch/></button>
                </form>
              </div>
            </div>
          </div>
          {
            rows.length !== 0 ? (<div className="usersContainer">
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={20}
                disableSelectionOnClick
                className="usersTable"
                autoWidth
                autoHeight
                sx={{
                  fontSize: 14,
                  backgroundColor: "white",
                  boxShadow: 0,
                  fontFamily: "Roboto",
                  headerColor: "white",
                  color: "black",
                  padding: 2,
                  "& .MuiDataGrid-cell:hover" : {
                    color: "primary.main",
                  },
                }}
              />
              {
                totalfilterStudents > resultPerPage &&
                <div className="paginationContainer">
                  <Pagination
                    activePage={CurrentPage}
                    itemsCountPerPage={resultPerPage}
                    totalItemsCount={totalfilterStudents}
                    onChange={e => setCurrentPage(e)}

                    className="paginationBox"

                    // prevPageText="<"
                    firstPageText="1st"
                    lastPageText="Last"
                    // nextPageText=">"
                    itemClass='page-item'
                    linkClass='page-link'
                    activeClass='pageItemActive'
                    activeLinkClass='pageLinkActive'
                  />
                </div>
              }
            </div>) : <NoDataFound />
          }


        </div>
      )}
    </div>
  );
};

export default AsignedApplication;
