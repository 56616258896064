exports.getDate = (date) =>{
    let dates = new Date(date)

    const d = dates.toString().split('G')[0]
    return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} `
}

exports.getDateTime = (date) =>{
    // let dates = new Date(date)
    // console.log(date)
    // const d = dates.toString().split('G')[0]

    // return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} - ${d.split(' ')[4]}  `

    const d = date.toString().split('.')[0]

    const d2 = d.toString().split('T')

    return  `${d2[0]} ${d2[1]} `


}


exports.getTime = (time) =>{

    const t =  time.split('-')
    
    return  `${t[0]}:${t[1]}:${t[2]} `
}