import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_FAIL,
  LOAD_USER_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,

  ALL_USER_FAIL,
  ALL_USER_REQUEST,
  ALL_USER_SUCCESS,

  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,

  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_RESET,
  DELETE_USER_SUCCESS,

  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,

  CLEAR_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  INVITE_USER_REQUEST,
  INVITE_USER_FAIL,
  INVITE_USER_SUCCESS,
  CREATE_USER_RESET,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  SEND_OTP_RESET,
  ALL_USER_FOR_ASSIGN_REQUEST,
  ALL_USER_FOR_ASSIGN_SUCCESS,
  ALL_USER_FOR_ASSIGN_FAIL,
  INSTALL_MAIL_REQUEST,
  INSTALL_MAIL_SUCCESS,
  INSTALL_MAIL_RESET,
  INSTALL_MAIL_FAIL,
} from "../constants/userConstant";

export const usertReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_USER_REQUEST:
    case LOAD_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case LOAD_USER_SUCCESS:

      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
      };

    case LOGIN_FAIL:
    case REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        // error: action.payload,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const createUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
    case INVITE_USER_REQUEST:
    case INSTALL_MAIL_REQUEST:
      return {
        loading: true,
        success: false
      };

    case CREATE_USER_SUCCESS:
    case INVITE_USER_SUCCESS:
    case INSTALL_MAIL_SUCCESS:

      return {
        ...state,
        loading: false,
        success: true

      };

    case CREATE_USER_RESET:
    case INSTALL_MAIL_RESET:
      return {
        ...state,
        loading: false,
        success: false
      };



    case CREATE_USER_FAIL:
    case INVITE_USER_FAIL:
    case INSTALL_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const profiletReducers = (state = { profile: {} }, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };


    case UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
    case UPDATE_USER_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case DELETE_USER_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
    case UPDATE_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const forgotPasswordReducers = (state = { forget: {} }, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const allUsersReducers = (state = { users: [] }, action) => {
  switch (action.type) {
    case ALL_USER_REQUEST:
    case ALL_USER_FOR_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
        resultPerPage: action.payload.resultPerPage,
      };

    case ALL_USER_FOR_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
      };


    case ALL_USER_FAIL:
    case ALL_USER_FOR_ASSIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const userDetailsReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };


    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const sendOtpReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return {
        loading: true,
        success: false
      };

    case SEND_OTP_SUCCESS:

      return {
        ...state,
        loading: false,
        success: true

      };

    case SEND_OTP_RESET:
      return {
        ...state,
        loading: false,
        success: false
      };



    case SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        success: false
        , error: action.payload
      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};