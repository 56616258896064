import React, { useState, useEffect, Fragment } from "react";
import "./FileManagement.css";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "./DashboardHeader";
import { useSelector, useDispatch } from "react-redux";
import { FaDownload, FaUserAlt } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import { getFlesData } from "../../actions/fileAction";
import NoDataFound from "../../components/NoDataFound";
import MetaData from "../../components/MetaData";
import { toast } from "react-toastify";
import { clearError } from "../../actions/userAction";
import { BiDownload } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ underLine, setUnderLine }) => {
  setUnderLine('file-management')

  const dispatch = useDispatch();
  const { error, students: files, loading } = useSelector((state) => state.files);

  useEffect(() => {
    dispatch(getFlesData())

    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
  }, [dispatch]);

  const Navigate = useNavigate();
  const { user } = useSelector((state) => state.users);
  useEffect(() => {
    if (user?.role !== 'admin' && user?.role !== 'supervisor' && user?.role !== 'agent') {
      Navigate('/dashboard')
    }
  }, [user])


  const columns = [
    { field: "id", headerName: "S No.", minWidth: 100, flex: 0.2 },


    {
      field: "user_name",
      headerName: "User Name",
      minWidth: 120,
      flex: 0.5,
    },

    { field: "role", headerName: "Role", minWidth: 50, flex: 0.4 },

    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 150,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        // console.log("dsf",params);
        return (
          <Fragment className="actionIconBox">
            <a href={`${params.row.url ? params.row.url : ""}`} className="fileDownloadBtn" download={'excel'}>
              <FaDownload />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const rows = [];

  files &&
    files.forEach((item, i) => {
      let date = new Date(item?.createdAt)

      rows.push({
        id: i + 1,
        url: item.file.url ? item.file.url : '',
        user_name: item.userId.name,
        role: item.userId.role,
        createAt: date.toString().split('G')[0]
      });
    });

  return (
    <div className="dashboardMain">
      <MetaData title={"File Management"} />
      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />
      </div>

      <div className="dashboardContainer">
        <DashboardHeader heading={"File Management"} />


        <div className="D-ButtonLayer">
          <div className="BlRow">
            <div className="BlItem BlItem4">
              <div>
                <p>Total Files</p>
                <p>{files && files.length}</p>
              </div>
              <div className="">
                <FaUserAlt />
              </div>
            </div>
            {/* <div className="BlItem ">
                <div>
                  <p>Total Students</p>
                  <p>250</p>
                </div>
                <div className="BlItem2">
                  <FaUserAlt />
                </div>
              </div>
              <div className="BlItem ">
                <div>
                  <p>Total Students</p>
                  <p>250</p>
                </div>
                <div className="BlItem3">
                  <FaUserAlt />
                </div>
              </div>
              <div className="BlItem ">
                <div>
                  <p>Total Students</p>
                  <p>250</p>
                </div>
                <div className="BlItem4">
                  <FaUserAlt />
                </div>
              </div> */}

          </div>
        </div>
        <div className="usersContainer">
          {
            rows.length !== 0 ? (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={20}
                disableSelectionOnClick
                className="usersTable"
                autoWidth
                  autoHeight

                max
                sx={{
                  fontSize: 14,
                  backgroundColor: 'white',
                  fontFamily: 'Roboto',
                  color: 'black',
                  headerColor: 'black',
                  padding: 2,
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },

                }}
              />
            ) : <NoDataFound />
          }



        </div>
      </div>


    </div>
  );
};

export default Dashboard;
