import React, { useState, useEffect, Fragment } from "react";
import "./FileManagement.css";
import Sidebar from "../../components/Sidebar";
import AsignedHeader from "./AsignedHeader";
import { useSelector, useDispatch } from "react-redux";
import { FaPencilAlt, FaSearch, FaStreetView, FaUserAlt, FaUserPlus, FaUsers } from "react-icons/fa";
import { GrUpdate, GrView } from "react-icons/gr";
import { DataGrid } from "@mui/x-data-grid";
import { getStudentsData } from "../../actions/fileAction";
import { toast } from "react-toastify";
import { clearError } from "../../actions/fileAction";
import Loading from "../../components/Loading/loading";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import Pagination from "react-js-pagination";
import { AiFillEye } from "react-icons/ai";
import { getStatus } from "../../components/student";
// import { Link } from "@mui/material";


// Modal.setAppElement('#yourAppElement');


const StudentsApplication = ({ underLine, setUnderLine }) => {
  setUnderLine('app-management')

  const dispatch = useDispatch();
  const { error, students, resultPerPage, totalfilterStudents, loading } = useSelector((state) => state.students);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast.error(error)
      dispatch(clearError())
    }
  }, [error]);

  const [searchWord, setSearchWord] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    searchHandler()
  }, [CurrentPage]);

  const Navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'admin' && user?.role !== 'agent' && user?.role !== 'counsellor' && user?.role !== 'processor'  && user?.role !== 'supervisor') {
      Navigate('/dashboard');
    }
  }, [user])

  const searchHandler = () => {
    
    if (user?._id) dispatch(getStudentsData(searchWord, user?._id, CurrentPage))
  }
  const searchByHandler = () => {
    setCurrentPage(1)
    if (user?._id) dispatch(getStudentsData(searchWord, user?._id, CurrentPage))
  }


  const columns = [
    { field: "id", headerName: "S No.", minWidth: 200, flex: 0.5 },


    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 0.5,
    },
    {

      // -----
      field: "status",
      headerName: "Status",
      minWidth: 180,
      flex: 0.3,
      // sortable: false,
      renderCell: (params) => {
        // console.log("dsf",params);
        const status = getStatus(params.row.status)
        const statusNo = params.row.status
        return (
          <div key={params.id} className="actionIconBox">
            <p className={
              (statusNo === '0' && 'newApp') ||
              (statusNo === '1' && 'underReviewApp') ||
              (statusNo === '2' && 'underReviewApp') ||
              (statusNo === '3' && 'approvedApp') ||
              (statusNo === '4' && 'rejectApp')
            } >{status}</p>
          </div>
        );
      },
    },

    {
      field: "country",
      headerName: "Country",
      minWidth: 150,
      flex: 0.5,
    },


    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 220,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        console.log("dsf", params.id);
        return (
          <div key={params.id} >

            <Link to={`/student/details/${params.row.id_}`} className="student-icons">
              <AiFillEye />
            </Link>

            <Link to={`/student/details/update/${params.row.id_}`} className="student-icons">
              <FaPencilAlt />
            </Link>
            {
              user?.role !== 'agent' &&
              <Link className="student-icons" to={`/student/asign/${params.row.id_}`} >
                <FaUserPlus />
              </Link>
            }

          </div>
        );
      },
    },
  ];

  const rows = [];

  students &&
    students.forEach((item, i) => {
      let date = new Date(item?.createdAt)

      rows.push({
        id: item?.Student_Id || 'GR-None',
        id_: item._id,
        name: item.Name,
        country: item.Country,
        status: item.status,
        createAt: date.toString().split('G')[0]
        ,
      });
    });

  // modal code 


  return (
    <div className="dashboardMain">
      <MetaData title={"Application Management"} />

      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />

      </div>
      {
        loading ? <Loading /> : (

          <div className="dashboardContainer">
            <AsignedHeader heading={" Application Management"} />


            <div className="appButton">
              <Link to={'/create/application'} className="docBtn">+ Application</Link>
            </div>
            <div className="D-ButtonLayer">

              <div className="BlRow1">
                <div className="BlItem BlItem4">
                  <div>
                    <p>Total Applications</p>
                    <p>{totalfilterStudents && totalfilterStudents}</p>
                  </div>
                  <div className="">
                    <FaUsers />
                  </div>
                </div>

                <div className="student-search-box">
                  <form action="" onSubmit={searchByHandler}>
                    <input type="text" placeholder="Search something here..." value={searchWord} onChange={e => setSearchWord(e.target.value)} />
                    <button type="submit" className="submitBtn" onClick={searchByHandler}  value={'find'} ><FaSearch/></button>

                  </form>
                </div>
              </div>

            </div>

            <div className="usersContainer">

              {
                rows.length !== 0 ? (<DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  disableSelectionOnClick
                  className="usersTable"
                  // autoHeight
                  autoWidth
                  autoHeight


                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white',
                    boxShadow: 0,
                    fontFamily: 'Roboto',
                    color: 'black',
                    //   headerColor: 'black',
                    padding: 2,
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },

                  }}
                />) : <NoDataFound />
              }


            </div>

            {
              totalfilterStudents > resultPerPage &&

              <div className="paginationContainer">
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={totalfilterStudents}
                  onChange={e => setCurrentPage(e)}

                  className="paginationBox"

                  // prevPageText="<"
                  firstPageText="1st"
                  lastPageText="Last"
                  // nextPageText=">"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            }

          </div>
        )
      }


    </div>
  );
};

export default StudentsApplication;
