import React, { useEffect, useState } from "react";
// import "./FileManagement.css";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { FaPencilAlt, FaUniversity, FaUsers } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getMonitorUsersAction } from "../../actions/monitoringAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import Sidebar from "../../components/Sidebar";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";
import { INSTALL_MAIL_RESET } from "../../constants/userConstant";
import { installMail } from "../../actions/userAction";




const StudentsApplication = ({ underLine, setUnderLine }) => {
  setUnderLine('monitoring')

  const dispatch = useDispatch();
  const { error, users, resultPerPage, totalUsers, loading } = useSelector((state) => state.getMonitorUsers);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast.error(error)
      dispatch(clearError())
    }
  }, [error]);

  const [searchWord, setSearchWord] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    searchHandler()
  }, []);

  const Navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'admin') {
      Navigate('/dashboard');
    }
  }, [user])

  // ----------------------------------------------- Install mail ---------------------------------------------------


  const { loading: mailLoading, success, error: mailError } = useSelector(state => state.createUser)

  useEffect(() => {

    if (mailError) {
      toast.error(mailError);
      dispatch(clearError());
    }

    if (success) {
      toast.success("Email send successful.");
      dispatch({ type: INSTALL_MAIL_RESET });
    }

  }, [mailError, success]);


  const sendInstallMail = (id) => {
    dispatch(installMail(id))

  }


  const searchHandler = () => {

    dispatch(getMonitorUsersAction())
  }
  //   const searchByHandler = () => {
  //     setCurrentPage(1)
  //     dispatch(getAllUniversityAction(CurrentPage, searchWord))
  //   }


  const columns = [
    { field: "id", headerName: "S No.", minWidth: 50, flex: 0.2 },


    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 0.5,
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 0.5,
    },


    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
    },

    {
      field: "install",
      headerName: "Install Monitoring",
      type: "number",
      minWidth: 220,
      flex: 0.3,
      textAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        console.log("dsf", params);
        return (
          <div key={params.id} >
            <p>{params.row.install ? "Yes" : "No"}</p>
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 220,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        console.log("dsf", params.id);
        return (
          <div key={params.id} >

            {
              params.row.install ?
                <Link to={`/monitoring/${params.row.id_}`} className="student-icons">
                  <AiFillEye />
                </Link>

                :
                <>
                  {

                    !mailLoading ?
                      <>
                        <button onClick={e => sendInstallMail(params.row.id_)} className="student-icons sendMailBtn">Send email
                        </button>
                      </> : ""

                  }
                </>
            }


          </div>
        );
      },
    },
  ];

  const rows = [];

  users &&
    users.forEach((item, i) => {
      let date = new Date(item?.createAt)

      rows.push({
        id: i + 1,
        id_: item._id,
        name: item.name,
        email: item.email,
        install: item.monitorLogin,
        createAt: date.toString().split('G')[0]
      });
    });

  // modal code 




  return (
    <div className="dashboardMain">
      <MetaData title={"Monitoring"} />

      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />

      </div>
      {
        (mailLoading || loading) ? <Loading /> : (

          <div className="dashboardContainer">
            <AsignedHeader heading={"Monitoring Users"} />



            <div className="D-ButtonLayer">

              <div className="BlRow1">
                <div className="BlItem BlItem4">
                  <div>
                    <p>Total Users</p>
                    <p>{users?.length}</p>
                  </div>
                  <div className="">
                    <FaUsers />
                  </div>
                </div>

                <div className="student-search-box">
                  {/* <form action="" onSubmit={searchByHandler}>
                    <input type="text" placeholder="Search something here..." value={searchWord} onChange={e => setSearchWord(e.target.value)} />
                    <button type="submit" className="submitBtn" onClick={searchByHandler} value={'find'} ><FaSearch /></button>

                  </form> */}
                </div>
              </div>

            </div>

            <div className="usersContainer">

              {
                rows.length !== 0 ? (<DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  disableSelectionOnClick
                  className="usersTable"
                  // autoHeight
                  autoWidth
                  autoHeight


                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white',
                    boxShadow: 0,
                    fontFamily: 'Roboto',
                    color: 'black',
                    //   headerColor: 'black',
                    padding: 2,
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },

                  }}
                />) : <NoDataFound />
              }


            </div>

            {/* {
              totalUsers > resultPerPage &&

              <div className="paginationContainer">
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={totalUsers}
                  onChange={e => setCurrentPage(e)}

                  className="paginationBox"

                  // prevPageText="<"
                  firstPageText="1st"
                  lastPageText="Last"
                  // nextPageText=">"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            } */}

          </div>
        )
      }


    </div>
  );
};

export default StudentsApplication;
