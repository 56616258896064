import React, { useState, useEffect, Fragment } from "react";
import "./FileManagement.css";
import Sidebar from "../../components/Sidebar";
import AsignedHeader from "./AsignedHeader.js";
import { useSelector, useDispatch } from "react-redux";
import { FaPencilAlt, FaStreetView, FaUserAlt, FaUserPlus } from "react-icons/fa";
import { GrUpdate, GrView } from "react-icons/gr";
import { DataGrid } from "@mui/x-data-grid";
import {
    getStudentsData,
    getAsignedApplications,
    getApplications,
} from "../../actions/fileAction";
import { toast } from "react-toastify";
import { clearError } from "../../actions/fileAction";
import Loading from "../../components/Loading/loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import { AiFillEye } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { getStatus } from "../../components/student.js";
// import { Link } from "@mui/material";

// Modal.setAppElement('#yourAppElement');

const AsignedApplication = ({ underLine, setUnderLine }) => {
    setUnderLine("");

    const { id } = useParams()

    const [CurrentPage, setCurrentPage] = useState(1)

    const dispatch = useDispatch();
    const { error, students, resultPerPage, totalfilterStudents, user: appUser, loading } = useSelector(
        (state) => state.asignApplications
    );

    const { user } = useSelector((state) => state.users);

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error]);

    useEffect(() => {
        searchHandler();
    }, [CurrentPage]);

    const Navigate = useNavigate();

    useEffect(() => {
        if (user?.role !== 'admin') {
            Navigate('/dashboard')
        }
    }, [user])

    const columns = [
        { field: "id", headerName: "S No.", minWidth: 80, flex: 0.5 },

        {
            field: "name",
            headerName: "Name",
            minWidth: 200,
            flex: 0.5,
        },
        {

            // -----
            field: "status",
            headerName: "Status",
            minWidth: 220,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                // console.log("dsf",params);
                const status = getStatus(params.row.status)
                const statusNo = params.row.status

                let className = 'unknown'

                console.log(typeof statusNo)


                return (
                    <Fragment className="actionIconBox">
                        <p className={
                            (statusNo === '0' && 'newApp') ||
                            (statusNo === '1' && 'underReviewApp') ||
                            (statusNo === '2' && 'underReviewApp') ||
                            (statusNo === '3' && 'approvedApp') ||
                            (statusNo === '4' && 'rejectApp')
                        } >{status}</p>
                    </Fragment>
                );
            },
        },
        {
            field: "country",
            headerName: "Country",
            minWidth: 150,
            flex: 0.5,
        },

        {
            field: "createAt",
            headerName: "Create At",
            minWidth: 200,
            flex: 0.5,
            // cellClassName: (params) => {
            //   return (params.getValue(params.id, "role"))
            // },
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "number",
            minWidth: 240,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                // console.log("dsf",params.row.id_);
                return (
                    <Fragment className="actionIconBox">
                        <Link
                            to={`/student/details/${params.row.id_}`}
                            className="student-icons"
                        >
                            <AiFillEye />
                        </Link>
                        <Link
                            to={`/student/details/update/${params.row.id_}`}
                            className="student-icons"
                        >
                            <FaPencilAlt />
                        </Link>
                        {/* <Link className="student-icons"to={`/student/asign/${params.id}`} >
            <FaUserPlus/>
          </Link> */}
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    students &&
        students.forEach((item, i) => {
            let date = new Date(item?.createdAt)

            rows.push({
                id_: item._id,
                id: i + 1,
                name: item.Name,
                status: item.status,

                country: item.Country,
                createAt: date.toString().split('G')[0]
            });
        });



    const searchHandler = (e) => {
        dispatch(getApplications(id, CurrentPage));
    };

    return (
        <div className="dashboardMain">
            <MetaData title={"Application Management"} />

            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar underLine={underLine} />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    <AsignedHeader heading={"Application"} />



                    <div className="D-ButtonLayer">
                        <div className="BlRow1">
                            <div className="BlItem BlItem4">
                                <div>
                                    <p>Total Applications</p>
                                    <p>{totalfilterStudents && totalfilterStudents}</p>
                                </div>
                                <div className="">
                                    <FaUserAlt />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div>
                        {
                            rows.length !== 0 ? (<div className="usersContainer">
                                {

                                    (appUser?.role === 'agent' || appUser?.role === 'admin' || appUser?.role === 'supervisor') ?
                                        <div className="appUserBox">
                                            <h4>{appUser?.name} | {appUser?.email} | {appUser?.role}</h4>
                                            <h3>Uploaded Applications</h3>
                                        </div>
                                        :
                                        <div className="appUserBox">
                                            <h4>{appUser?.name} | {appUser?.email} | {appUser?.role}</h4>
                                            <h3>Assigned Applications</h3>
                                        </div>

                                }

                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={20}
                                    disableSelectionOnClick
                                    className="usersTable"
                                    autoWidth
                                    autoHeight
                                    sx={{
                                        fontSize: 14,
                                        backgroundColor: "white",
                                        boxShadow: 0,
                                        fontFamily: "Roboto",

                                        headerColor: "white",
                                        color: "black",
                                        padding: 2,
                                        "& .MuiDataGrid-cell:hover": {
                                            color: "primary.main",
                                        },
                                    }}
                                />
                                {
                                    totalfilterStudents > resultPerPage &&
                                    <div className="paginationContainer">
                                        <Pagination
                                            activePage={CurrentPage}
                                            itemsCountPerPage={resultPerPage}
                                            totalItemsCount={totalfilterStudents}
                                            onChange={e => setCurrentPage(e)}

                                            className="paginationBox"

                                            // prevPageText="<"
                                            firstPageText="1st"
                                            lastPageText="Last"
                                            // nextPageText=">"
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='pageItemActive'
                                            activeLinkClass='pageLinkActive'
                                        />
                                    </div>
                                }
                            </div>) : <NoDataFound />
                        }
                    </div>


                </div>
            )}
        </div>
    );
};

export default AsignedApplication;
